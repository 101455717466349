import React from "react"

// Libraries
import { StaticImage } from "gatsby-plugin-image"

// Components
import Seo from "../components/seo"
import Header from "../components/header"
import Hero from "../components/hero"
import ThanksComponent from "../components/thank-you"
import BodyLanding from "../components/body-landing"
import TopBullets from "./screens/clinician-patient-connection/top-bullets"

const ThankYou = ({ location }) => {
	const firstName = (location.state) ? location.state.first_name : ""
	return (
		<main>
			<Header />
			<Hero title="Minutes Matter">
				<StaticImage
					width={1300}
					loading="eager"
					src="../assets/images/clinician-patient-connection/hero.jpg"
					className="restrictImage"
					alt="Clinician and patient split screen"
				/>
			</Hero>
			<ThanksComponent first_name={firstName}>
				<TopBullets />
			</ThanksComponent>
			<BodyLanding landingPage="clinician-patient-connection" />
		</main>
	)
}

export default ThankYou

export const Head = () => (
	<Seo
	  title="Minutes Matter"
	  description="Testing with Visby Medical represents an untapped growth opportunity for your urgent care."
	/>
  )
  